
import React from "react";
import Layout from "@/components/layout";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import { Helmet } from "react-helmet";
import { withPrefix} from "gatsby"
import Logo from "../assets/images/new/footer/sapientia.svg"

import "@/css/prova.css";

const QuestionsPage = () => {

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Provas">

        <Helmet>
            <style>{`body { background-color: ${'#f1f1f1 !important'}; }`}</style>
            <script src={withPrefix('script.js')} type="text/javascript" /> 
            <script src={withPrefix('questions.js')} type="text/javascript" />
        </Helmet>

        <div  className="start_btn">
            <img src={Logo} alt="Celereitas" className="logo"/>
           <div className="buttons">
           <button  className="op1">Prova 1</button>
            <button  className="op2">Prova 2</button>
           </div>
        </div>

        <div  className="info_box">
            <div className="info-title"><span>Regras para realização da prova</span></div>
            <div className="buttons">
                <button className="quit">Sair</button>
                <button className="restart">Continuar</button>
            </div>
        </div>

        <div  className="quiz_box">
            <header>
                <div className="title"></div>
                <div className="time_line"></div>
                <button id="header-quit" className="quit">Sair</button>
            </header>
            <section >
                <div className="que_text">

                </div>
                <div className="option_list">

                </div>
            </section>
  
            <footer >
                <div className="total_que">

                </div>
                <button  className="next_btn">Próxima</button>
            </footer>
        </div>
    
        <div className="result_box">
            <div className="icon">
              
            </div>
           
            <div className="score_text">
               
            </div>
            <div className="result_question">
               
                <span></span>
            </div>
            <div className="buttons">
                <button className="restart">Refazer</button>
                <button className="quit">Sair</button>
            </div>
        </div>
        
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default QuestionsPage;
